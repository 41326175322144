import React from 'react'
import { trans } from 'sharedKernel/i18n'
import { useNavigate } from 'react-router-dom'
import { useSelector, TReducers } from 'store'
import { HotsiteChallenge, HotsiteChallengeProps } from '../../store/contracts'
import Tippy from '@tippyjs/react'
import { followCursor } from 'tippy.js'

// Componentes
import { BadgeArea, Badge, BaseButton, OpenChallengesCard, CardHeader } from 'iebt-zing-storybook'
import { SwiperWrapper } from 'components/swiper'
import { Container, Row, Col } from 'react-bootstrap'
import {
  OpenChallengesWrapper,
  OpenChallengesInfoWrapper,
  OpenChallengesTitle,
  OpenChallengesSwiperWrapper
} from './styles'
import { isCurrentDateBiggerThanDeadline, getUnnabreviatedDate } from 'sharedKernel/handlers'
import { handleApplyAction } from '../../behavior'
import { Loading } from 'components/loading'

export const OpenChallengesSection = ({ openChallenges }): JSX.Element => {
  const navigate = useNavigate()
  const hotsiteChallenges: HotsiteChallengeProps = useSelector((state: TReducers) => state.hotsiteChallengesData)
  const isAuthenticated = useSelector((state: TReducers) => state.auth.isAuthenticated)
  const userPermissions = useSelector((state: TReducers) => state.auth.permissions)

  const openChallengesLabels = {
    title: trans('Confira alguns Eventos e Programas de Inovação abertos'),
    btnContent: trans('Ver mais'),
    closed: trans('Encerrado'),
    signUpTooltip: trans('Veja mais detalhes do evento'),
    closedTooltip: trans('A data limite foi atingida')
  }

  const getChallenges = (challenge: HotsiteChallenge): JSX.Element => {
    const headerChallenge = <CardHeader coverImage={challenge.style.coverImagePath} />
    const titleChallenge = challenge.config.title
    const shortDescriptionChallenge = challenge.style.shortDescription
    const deadline = getUnnabreviatedDate(challenge.config.step.finalSelectionDate)
    const isClosed = isCurrentDateBiggerThanDeadline(challenge.config.step.finalSelectionDate)
    const tooltipContent = isClosed ? openChallengesLabels.closedTooltip : openChallengesLabels.signUpTooltip
    const buttons = (
      <Tippy
        content={tooltipContent}
        followCursor={true}
        plugins={[followCursor]}
        animation="scale"
        arrow={false}
        theme="primary"
      >
        <div>
          {isClosed
            ? (<BaseButton
            btnContent={openChallengesLabels.closed}
            variant='risk-secondary'
            size='medium'
            disabled
          />)
            : (
          <BaseButton
            btnContent={openChallengesLabels.btnContent}
            variant='risk-secondary'
            size='medium'
            onClick={() => navigate(handleApplyAction(isAuthenticated, userPermissions, challenge.id))}
          />
              )}
        </div>
      </Tippy>)
    return (
      <OpenChallengesCard
        header={headerChallenge}
        title={titleChallenge}
        shortDescription={shortDescriptionChallenge}
        // longDescription={longDescriptionChallenge}
        badges={
          <BadgeArea badgeQty={1}>
            <Badge deadline={deadline} icon={['fas', 'calendar-day']} type="string">
              {deadline}
            </Badge>
          </BadgeArea>
        }
        buttons={buttons}
      />
    )
  }

  const orderChallenges = (allChalenges: HotsiteChallengeProps): any[] => {
    const activeChallenges = allChalenges.rows?.filter((challenge) => !isCurrentDateBiggerThanDeadline(challenge.config.step.finalSelectionDate))
    const inactiveChallenges = allChalenges.rows?.filter((challenge) => isCurrentDateBiggerThanDeadline(challenge.config.step.finalSelectionDate))

    activeChallenges?.sort((a, b) => new Date(a.config.step.finalSelectionDate).getTime() - new Date(b.config.step.finalSelectionDate).getTime())
    inactiveChallenges?.sort((a, b) => new Date(a.config.step.finalSelectionDate).getTime() - new Date(b.config.step.finalSelectionDate).getTime())

    return [...activeChallenges ?? [], ...inactiveChallenges ?? []]
  }

  const orderedChallenges = orderChallenges(hotsiteChallenges)
  const challenges = orderedChallenges !== undefined
    ? orderedChallenges.map((challenge) => getChallenges(challenge))
    : openChallenges.map((challenge) => getChallenges(challenge))

  const cardsList = [...challenges]

  const buildBreakpoints = (): any => {
    return {
      768: {
        slidesPerView: 2,
        spaceBetween: 25
      },
      1220: {
        slidesPerView: 3,
        spaceBetween: 25
      },
      1440: {
        slidesPerView: 4,
        spaceBetween: 25
      }
    }
  }

  return (
    <OpenChallengesWrapper fluid>
      {/* Como está sendo utilizado o componente do react-bootstrap direto, utilizo as próprias classes que o bootstrap fornece */}
      <Row className="d-flex w-full flex-column">
        <Col>
          {/* As classes com prefixo "animate" são necessários para realizar as animações. Doc: https://animate.style/ */}
          <OpenChallengesInfoWrapper className="animate__animated animate__zoomIn animate__slow">
            <OpenChallengesTitle>{openChallengesLabels.title}</OpenChallengesTitle>
          </OpenChallengesInfoWrapper>
          {
            openChallenges.length > 0
              ? <OpenChallengesSwiperWrapper className="mt-5 animate__animated animate__zoomIn animate__delay-1s animate__slow">
                <Container>
                  <SwiperWrapper
                    breakpoints={buildBreakpoints()}
                    carouselItems={cardsList}
                    arrowColor='var(--primary)'
                    swipeOnMouseWheel={false}
                  />
                </Container>
              </OpenChallengesSwiperWrapper>
              : <Loading />
          }

        </Col>
      </Row>
    </OpenChallengesWrapper>
  )
}
